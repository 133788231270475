export const defaultPagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: ["10", "20", "25"],
}

export const AUTHORIZATION_KEY = "manyfastScan-portal-access";
export const DEVICE_ID = "manyfastScan-portal-device-id";

export const WS_PING_ALIVE_MESSAGE = "PING_ALIVE";

export const DEFAULT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
};

export const DATE_STRING_FORMAT_BACKEND = "YYYY-MM-DD";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import {
  accountDataReducer,
  initialState as accountData,
} from "./reducers/accountDataReducer";
import { AccountDataProvider } from "./context/accountData";

import "./styles/style.scss";
import * as Sentry from "@sentry/react";

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

Sentry.init({
  dsn: "https://c71dbb4fcde65bb73fa423bd1cc21d82@monitoring.dataflowdev.com/41",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.SENTRY_ENVIRONMENT,
  tracesSampleRate: process.env.SENTRY_REPORTING_FREQUENCY ? process.env.SENTRY_REPORTING_FREQUENCY : 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: process.env.NODE_ENV === "development" ? 0.1 : 1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <AccountDataProvider
    initialState={accountData}
    reducer={accountDataReducer}
  >
    <App />
  </AccountDataProvider>,
  document.getElementById("root")
);
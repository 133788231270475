const userTypeLookup = {
    "a": "Admin",
    "u": "AccountAdmin",
    "e": "Employee",
}

export const MANIFEST_ITEM_DOWNLOAD_TYPES = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "OnlyFeatured",
        label: "Only Featured"
    }
]


export const ITEM_MODE_INVENTORY = "Inventory";

export const GetUserType = (type) => {
    return userTypeLookup[type];
}
export const LOGIN_API_PATH = "auth--dummy-suffix-url/login";
export const AUTH_USER_API_PATH = "auth--dummy-suffix-url/auth-user";

export const MANIFESTS_API_PATH = "manifest";
export const GET_ALL_MANIFESTS_API_PATH = `${MANIFESTS_API_PATH}/all`;
export const GET_MANIFESTS_STATS_API_PATH = `${MANIFESTS_API_PATH}/stats?manifest_ids={manifestIds}`;
export const GET_SINGLE_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}?id={manifestId}`;
export const GET_MANIFEST_LAST_LOT_API_PATH = `${MANIFESTS_API_PATH}/check-last-lot?id={manifestId}`;
export const SET_IS_HIDDEN_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}/set-is-hidden?id={manifestId}&is_hidden={isHidden}`;
export const ADD_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}/add`;
export const EDIT_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}/edit?id={manifestId}`;
export const DELETE_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}?id={manifestId}`;

export const MANIFEST_GROUPS_API_PATH = "manifest-group";
export const GET_ALL_MANIFEST_GROUPS_API_PATH = `${MANIFEST_GROUPS_API_PATH}/all`;
export const GET_ALL_MANIFEST_GROUPS_UNPAGINATED_API_PATH = `${MANIFEST_GROUPS_API_PATH}/all-unpaginated`;
export const GET_MANIFEST_GROUPS_STATS_API_PATH = `${MANIFEST_GROUPS_API_PATH}/stats?manifest_group_ids={manifestGroupIds}`;
export const GET_SINGLE_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}?id={manifestGroupId}`;
export const SET_IS_HIDDEN_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}/set-is-hidden?id={manifestGroupId}&is_hidden={isHidden}`;
export const ADD_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}/add`;
export const EDIT_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}/edit?id={manifestGroupId}`;
export const DELETE_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}?id={manifestGroupId}`;

export const VENDORS_API_PATH = "vendor";
export const GET_ALL_VENDORS_API_PATH = `${VENDORS_API_PATH}/all`;
export const GET_SINGLE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;
export const ADD_VENDOR_API_PATH = `${VENDORS_API_PATH}/add`;
export const EDIT_VENDOR_API_PATH = `${VENDORS_API_PATH}/edit?id={vendorId}`;
export const DELETE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;

export const ZPL_TEMPLATES_API_PATH = "zpl-template";
export const GET_ALL_ZPL_TEMPLATES_API_PATH = `${ZPL_TEMPLATES_API_PATH}/all`;
export const GET_SINGLE_ZPL_TEMPLATE_API_PATH = `${ZPL_TEMPLATES_API_PATH}?id={zplTemplateId}`;
export const ADD_ZPL_TEMPLATE_API_PATH = `${ZPL_TEMPLATES_API_PATH}/add`;
export const EDIT_ZPL_TEMPLATE_API_PATH = `${ZPL_TEMPLATES_API_PATH}/edit?id={zplTemplateId}`;
export const DELETE_ZPL_TEMPLATE_API_PATH = `${ZPL_TEMPLATES_API_PATH}?id={zplTemplateId}`;

export const DATA_INTEGRATIONS_API_PATH = "data-integration";
export const GET_ALL_DATA_INTEGRATIONS_API_PATH = `${DATA_INTEGRATIONS_API_PATH}/all`;

export const DATA_SETTINGS_API_PATH = "data-setting";
export const GET_ALL_DATA_SETTINGS_API_PATH = `${DATA_SETTINGS_API_PATH}/all`;
export const GET_ALL_DATA_SETTINGS_WITH_UPLOADED_MANIFESTS_MANIFEST_API_PATH = `${DATA_SETTINGS_API_PATH}/all-with-template-files-manifest?manifest_id={manifestId}`;
export const GET_ALL_DATA_SETTINGS_WITH_UPLOADED_MANIFESTS_MANIFEST_GROUP_API_PATH = `${DATA_SETTINGS_API_PATH}/all-with-template-files-manifest-group?manifest_group_id={manifestGroupId}`;
export const GET_SINGLE_DATA_SETTING_API_PATH = `${DATA_SETTINGS_API_PATH}?id={vendorId}`;
export const ADD_DATA_SETTING_API_PATH = `${DATA_SETTINGS_API_PATH}/add`;
export const EDIT_DATA_SETTING_API_PATH = `${DATA_SETTINGS_API_PATH}/edit?id={vendorId}`;
export const DELETE_DATA_SETTING_API_PATH = `${DATA_SETTINGS_API_PATH}?id={vendorId}`;

export const UPLOADED_MANIFESTS_API_PATH = "template-file";
export const GET_ALL_UPLOADED_MANIFESTS_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}/all`;
export const GET_SINGLE_UPLOADED_MANIFEST_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}?id={manifestItemId}`;
export const ADD_UPLOADED_MANIFEST_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}/add`;
export const EDIT_UPLOADED_MANIFEST_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}/edit?id={manifestItemId}`;
export const DELETE_UPLOADED_MANIFEST_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}?id={manifestItemId}`;

export const ACCOUNT_API_PATH = "account";
export const GET_DASHBOARD_API_PATH = `${ACCOUNT_API_PATH}/dashboard`;

export const MANIFEST_ITEMS_API_PATH = "manifest-item";
export const GET_ALL_MANIFEST_ITEMS_API_PATH = `${MANIFEST_ITEMS_API_PATH}/all`;
export const GET_SINGLE_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}?id={manifestItemId}`;
export const GET_MANIFEST_ITEM_PHOTOS_API_PATH = `${MANIFEST_ITEMS_API_PATH}/photos?id={manifestItemId}`;
export const ADD_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add`;
export const ADD_UPC_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add-upc`;
export const ADD_ITEM_NUM_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add-item-num`;
export const EDIT_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/edit?id={manifestItemId}`;
export const DELETE_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}?id={manifestItemId}`;

export const PRODUCT_CONDITIONS_API_PATH = "product-condition";
export const GET_ALL_PRODUCT_CONDITIONS_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}?id={productConditionId}`;
export const ADD_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/add`;
export const EDIT_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/edit?id={productConditionId}`;
export const DELETE_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}?id={productConditionId}`;

export const CATEGORIES_API_PATH = "category";
export const GET_ALL_CATEGORIES_API_PATH = `${CATEGORIES_API_PATH}/all`;
export const GET_SINGLE_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}?id={categoryId}`;
export const ADD_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}/add`;
export const EDIT_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}/edit?id={categoryId}`;
export const DELETE_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}?id={categoryId}`;

export const DEPARTMENTS_API_PATH = "department";
export const GET_ALL_DEPARTMENTS_API_PATH = `${DEPARTMENTS_API_PATH}/all`;
export const GET_SINGLE_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}?id={departmentId}`;
export const ADD_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}/add`;
export const EDIT_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}/edit?id={departmentId}`;
export const DELETE_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}?id={departmentId}`;

export const DOWNLOADS_API_PATH = "download";
export const MANIFEST_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-manifest`;

export const DOWNLOAD_LOGS_API_PATH = "download-log";
export const GET_ALL_DOWNLOAD_LOGS_API_PATH = `${DOWNLOAD_LOGS_API_PATH}/all`;
export const GET_SINGLE_DOWNLOAD_LOG_API_PATH = `${DOWNLOAD_LOGS_API_PATH}?id={downloadLogId}`;
import instance from "../axios";
import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY, DEVICE_ID } from "../global_constants";

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  instance.defaults.headers.common["X-Device-Id"] = "web";
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        instance.defaults.headers.common[
          "X-Device-Id"
        ] = `${data.device_id}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAuthUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      const deviceId = localStorage.getItem(DEVICE_ID);
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance.defaults.headers.common["X-Device-Id"] = deviceId;
      instance
        .get(endpoints.AUTH_USER_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const getAllManifests = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_MANIFESTS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getManifestsStats = (manifestIds) => {
  let path = endpoints.GET_MANIFESTS_STATS_API_PATH.replace("{manifestIds}", manifestIds);
  return instance.get(path);
};
export const getSingleManifest = (manifestId) => {
  let path = endpoints.GET_SINGLE_MANIFEST_API_PATH.replace("{manifestId}", manifestId);
  return instance.get(path);
};
export const getSingleManifestLastLot = (manifestId) => {
  let path = endpoints.GET_MANIFEST_LAST_LOT_API_PATH.replace("{manifestId}", manifestId);
  return instance.get(path);
};
export const setIsHiddenManifest = (manifestId, isHidden) => {
  let path = endpoints.SET_IS_HIDDEN_MANIFEST_API_PATH.replace("{manifestId}", manifestId).replace("{isHidden}", isHidden);
  return instance.get(path);
};
export const addManifest = (payload) => {
  let path = endpoints.ADD_MANIFEST_API_PATH;
  return instance.post(path, payload);
};
export const editManifest = (payload, manifestId) => {
  let path = endpoints.EDIT_MANIFEST_API_PATH.replace("{manifestId}", manifestId);
  return instance.post(path, payload);
};
export const deleteManifest = (manifestId) => {
  let path = endpoints.DELETE_MANIFEST_API_PATH.replace("{manifestId}", manifestId);
  return instance.delete(path);
};

export const getAllManifestGroups = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_MANIFEST_GROUPS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getAllManifestGroupsUnpaginated = () => {
  let path = endpoints.GET_ALL_MANIFEST_GROUPS_UNPAGINATED_API_PATH;
  return instance.get(path);
};
export const getManifestGroupsStats = (manifestGroupIds) => {
  let path = endpoints.GET_MANIFEST_GROUPS_STATS_API_PATH.replace("{manifestGroupIds}", manifestGroupIds);
  return instance.get(path);
};
export const getSingleManifestGroup = (manifestGroupId) => {
  let path = endpoints.GET_SINGLE_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId);
  return instance.get(path);
};
export const setIsHiddenManifestGroup = (manifestGroupId, isHidden) => {
  let path = endpoints.SET_IS_HIDDEN_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId).replace("{isHidden}", isHidden);
  return instance.get(path);
};
export const addManifestGroup = (payload) => {
  let path = endpoints.ADD_MANIFEST_GROUP_API_PATH;
  return instance.post(path, payload);
};
export const editManifestGroup = (payload, manifestGroupId) => {
  let path = endpoints.EDIT_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId);
  return instance.post(path, payload);
};
export const deleteManifestGroup = (manifestGroupId) => {
  let path = endpoints.DELETE_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId);
  return instance.delete(path);
};

export const getAllVendors = () => {
  let path = endpoints.GET_ALL_VENDORS_API_PATH;
  return instance.get(path);
};
export const getSingleVendor = (vendorId) => {
  let path = endpoints.GET_SINGLE_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.get(path);
};
export const addVendor = (payload) => {
  let path = endpoints.ADD_VENDOR_API_PATH;
  return instance.post(path, payload);
};
export const editVendor = (payload, vendorId) => {
  let path = endpoints.EDIT_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.post(path, payload);
};
export const deleteVendor = (vendorId) => {
  let path = endpoints.DELETE_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.delete(path);
};

export const getAllZplTemplates = () => {
  let path = endpoints.GET_ALL_ZPL_TEMPLATES_API_PATH;
  return instance.get(path);
};
export const getSingleZplTemplate = (zplTemplateId) => {
  let path = endpoints.GET_SINGLE_ZPL_TEMPLATE_API_PATH.replace("{zplTemplateId}", zplTemplateId);
  return instance.get(path);
};
export const addZplTemplate = (payload) => {
  let path = endpoints.ADD_ZPL_TEMPLATE_API_PATH;
  return instance.post(path, payload);
};
export const editZplTemplate = (payload, zplTemplateId) => {
  let path = endpoints.EDIT_ZPL_TEMPLATE_API_PATH.replace("{zplTemplateId}", zplTemplateId);
  return instance.post(path, payload);
};
export const deleteZplTemplate = (zplTemplateId) => {
  let path = endpoints.DELETE_ZPL_TEMPLATE_API_PATH.replace("{zplTemplateId}", zplTemplateId);
  return instance.delete(path);
};

export const getAllDataIntegrations = () => {
  let path = endpoints.GET_ALL_DATA_INTEGRATIONS_API_PATH;
  return instance.get(path);
};

export const getAllDataSettings = () => {
  let path = endpoints.GET_ALL_DATA_SETTINGS_API_PATH;
  return instance.get(path);
};
export const getAllDataSettingsWithUploadedManifestsManifest = (manifestId) => {
  let path = endpoints.GET_ALL_DATA_SETTINGS_WITH_UPLOADED_MANIFESTS_MANIFEST_API_PATH.replace("{manifestId}", manifestId);
  return instance.get(path);
};
export const getAllDataSettingsWithUploadedManifestsManifestGroup = (manifestGroupId) => {
  let path = endpoints.GET_ALL_DATA_SETTINGS_WITH_UPLOADED_MANIFESTS_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId);
  return instance.get(path);
};
export const getSingleDataSetting = (dataSettingId) => {
  let path = endpoints.GET_SINGLE_DATA_SETTING_API_PATH.replace("{dataSettingId}", dataSettingId);
  return instance.get(path);
};
export const addDataSetting = (payload) => {
  let path = endpoints.ADD_DATA_SETTING_API_PATH;
  return instance.post(path, payload);
};
export const editDataSetting = (payload, dataSettingId) => {
  let path = endpoints.EDIT_DATA_SETTING_API_PATH.replace("{dataSettingId}", dataSettingId);
  return instance.post(path, payload);
};
export const deleteDataSetting = (dataSettingId) => {
  let path = endpoints.DELETE_DATA_SETTING_API_PATH.replace("{dataSettingId}", dataSettingId);
  return instance.delete(path);
};

export const getAllUploadedManifests = () => {
  let path = endpoints.GET_ALL_UPLOADED_MANIFESTS_API_PATH;
  return instance.get(path);
};
export const getSingleUploadedManifest = (uploadedManifestId) => {
  let path = endpoints.GET_SINGLE_UPLOADED_MANIFEST_API_PATH.replace("{uploadedManifestId}", uploadedManifestId);
  return instance.get(path);
};
export const addUploadedManifest = (payload) => {
  let path = endpoints.ADD_UPLOADED_MANIFEST_API_PATH;
  return instance.post(path, payload);
};
export const editUploadedManifest = (payload, uploadedManifestId) => {
  let path = endpoints.EDIT_UPLOADED_MANIFEST_API_PATH.replace("{uploadedManifestId}", uploadedManifestId);
  return instance.post(path, payload);
};
export const deleteUploadedManifest = (uploadedManifestId) => {
  let path = endpoints.DELETE_UPLOADED_MANIFEST_API_PATH.replace("{uploadedManifestId}", uploadedManifestId);
  return instance.delete(path);
};

export const getMainDashboardUrl = () => {
  let path = endpoints.GET_DASHBOARD_API_PATH;
  return instance.get(path);
};

export const getAllManifestItems = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_MANIFEST_ITEMS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleManifestItem = (manifestItemId) => {
  let path = endpoints.GET_SINGLE_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.get(path);
};
export const getManifestItemPhotos = (manifestItemId) => {
  let path = endpoints.GET_MANIFEST_ITEM_PHOTOS_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.get(path);
};
export const addManifestItem = (payload) => {
  let path = endpoints.ADD_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const addUpcManifestItem = (payload) => {
  let path = endpoints.ADD_UPC_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const addItemNumManifestItem = (payload) => {
  let path = endpoints.ADD_ITEM_NUM_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const editManifestItem = (payload, manifestItemId) => {
  let path = endpoints.EDIT_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.post(path, payload);
};
export const deleteManifestItem = (manifestItemId) => {
  let path = endpoints.DELETE_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.delete(path);
};

export const getAllProductConditions = () => {
  let path = endpoints.GET_ALL_PRODUCT_CONDITIONS_API_PATH;
  return instance.get(path);
};
export const getSingleProductCondition = (productConditionId) => {
  let path = endpoints.GET_SINGLE_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.get(path);
};
export const addProductCondition = (payload) => {
  let path = endpoints.ADD_PRODUCT_CONDITION_API_PATH;
  return instance.post(path, payload);
};
export const editProductCondition = (payload, productConditionId) => {
  let path = endpoints.EDIT_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.post(path, payload);
};
export const deleteProductCondition = (productConditionId) => {
  let path = endpoints.DELETE_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.delete(path);
};

export const getAllCategories = () => {
  let path = endpoints.GET_ALL_CATEGORIES_API_PATH;
  return instance.get(path);
};
export const getSingleCategory = (categoryId) => {
  let path = endpoints.GET_SINGLE_CATEGORY_API_PATH.replace("{categoryId}", categoryId);
  return instance.get(path);
};
export const addCategory = (payload) => {
  let path = endpoints.ADD_CATEGORY_API_PATH;
  return instance.post(path, payload);
};
export const editCategory = (payload, categoryId) => {
  let path = endpoints.EDIT_CATEGORY_API_PATH.replace("{categoryId}", categoryId);
  return instance.post(path, payload);
};
export const deleteCategory = (categoryId) => {
  let path = endpoints.DELETE_CATEGORY_API_PATH.replace("{categoryId}", categoryId);
  return instance.delete(path);
};

export const getAllDepartments = () => {
  let path = endpoints.GET_ALL_DEPARTMENTS_API_PATH;
  return instance.get(path);
};
export const getSingleDepartment = (departmentId) => {
  let path = endpoints.GET_SINGLE_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.get(path);
};
export const addDepartment = (payload) => {
  let path = endpoints.ADD_DEPARTMENT_API_PATH;
  return instance.post(path, payload);
};
export const editDepartment = (payload, departmentId) => {
  let path = endpoints.EDIT_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.post(path, payload);
};
export const deleteDepartment = (departmentId) => {
  let path = endpoints.DELETE_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.delete(path);
};


export const startManifestDownload = (payload) => {
  let path = endpoints.MANIFEST_DOWNLOAD_API_PATH;
  return instance.post(path, payload);
};


export const getAllDownloadLogs = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_DOWNLOAD_LOGS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleDownloadLog = (downloadLogId) => {
  let path = endpoints.GET_SINGLE_DOWNLOAD_LOG_API_PATH.replace("{downloadLogId}", downloadLogId);
  return instance.get(path);
};

import React from "react";
import {
  CarOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  HomeOutlined,
  InboxOutlined,
  PercentageOutlined,
  SettingOutlined,
  ShopOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

import NotFoundPage from "./pages/404Page";

import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/login";

const HomePageComponent = React.lazy(() =>
  import("./pages/homePage")
);
const ManifestsPageComponent = React.lazy(() =>
  import("./pages/manifests")
);
const ManifestGroupsPageComponent = React.lazy(() =>
  import("./pages/manifestGroups")
);
const VendorsPageComponent = React.lazy(() =>
  import("./pages/vendors")
);
const ManifestItemsPageComponent = React.lazy(() =>
  import("./pages/manifestItems")
);
const DownloadLogsPageComponent = React.lazy(() =>
  import("./pages/downloadLogs")
);

export const INDEX_ROUTE = "/";
export const REGISTER_ROUTE = "/register";
export const MANIFESTS_ROUTE = "/manifests";
export const MANIFEST_GROUPS_ROUTE = "/manifest-groups";
export const VENDORS_ROUTE = "/vendors";
export const MANIFEST_ITEMS_ROUTE = "/manifest-items/:manifestId";
export const DOWNLOAD_LOGS_ROUTE = "/download-logs";

export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "home",
        path: INDEX_ROUTE,
        component: HomePageComponent,
      },
      {
        exact: true,
        name: "manifests",
        path: MANIFESTS_ROUTE,
        component: ManifestsPageComponent,
      },
      {
        exact: true,
        name: "manifest-groups",
        path: MANIFEST_GROUPS_ROUTE,
        component: ManifestGroupsPageComponent,
      },
      {
        exact: true,
        name: "vendors",
        path: VENDORS_ROUTE,
        component: VendorsPageComponent,
      },
      {
        exact: true,
        name: "manifest-items",
        path: MANIFEST_ITEMS_ROUTE,
        component: ManifestItemsPageComponent,
      },
      {
        exact: true,
        name: "download-logs",
        path: DOWNLOAD_LOGS_ROUTE,
        component: DownloadLogsPageComponent,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <DashboardOutlined />,
  },
  {
    name: "/manifest-generator",
    label: "Manifest Generator",
    icon: <SettingOutlined />,
    submenu: [
      {
        name: "manifests",
        label: "Manifests",
        route: MANIFESTS_ROUTE,
        icon: <ShopOutlined />,
      },
      {
        name: "manifest-groups",
        label: "Manifest Groups",
        route: MANIFEST_GROUPS_ROUTE,
        icon: <ShopOutlined />,
      },
      {
        name: "vendors",
        label: "Vendors",
        route: VENDORS_ROUTE,
        icon: <ShopOutlined />,
      },
    ]
  },
  {
    name: "/download",
    label: "Download",
    icon: <SettingOutlined />,
    submenu: [
      {
        name: "download-logs",
        label: "Download Logs",
        route: DOWNLOAD_LOGS_ROUTE,
        icon: <ShopOutlined />,
      },
    ]
  },
];
